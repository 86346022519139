import Vue from 'vue'
import Router from 'vue-router'
import {pc_routes} from "@/router/pc";
import {mobile_routes} from "@/router/mobile";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  //base: process.env.BASE_URL,
  base: process.env.BASE_URL,
  
  routes: [
      ...pc_routes,
      ...mobile_routes,
  ]
})
router.beforeEach((to, from, next) => {
  //当移动端试图访问pc端页面时
  if (/Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent)) {  // 判断设备
    if (to.path.includes('mobile/')) {
      next();
    } else {
      if (to.path.includes('pc/')) {
        window.location.href = '/mobile/index'
      } else {
        window.location.href = '/pc/index'
      }
    }
  } else {
    if (to.path.includes('/mobile')) {
      window.location.href = '/pc/index'
    } else {
      if (to.path.includes('/pc')) {
        next();
      } else {
        window.location.href = '/mobile/index'
      }
    }
  }
});
export default router;

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


import God from "./views/god";

export default {
  name: 'app',
  created() {
    if (!(/Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent))) {
      new God().init();
    }
  },
  mounted() {
    // this.bodyScale();
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  data: () => {
    return {
      screenWidth: document.documentElement.clientWidth
    }
  },
  watch:{
    screenWidth(val){
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if(!this.timer){
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function(){
          // 打印screenWidth变化的值
          console.log(that.screenWidth)
          if (!(/Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent))) {
            document.body.style.zoom = val / 1920;//放大缩小相应倍数
          }
          that.timer = false
        },400)
      }
    }
  },

  methods: {
    // bodyScale() {
    //   if (!(/Android |webos| iPhone |iPod| BlackBerry | iPad/i.test(navigator.userAgent))) {
    //     var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
    //     var scale = devicewidth / 1920; // 分母——设计稿的尺寸
    //     document.body.style.zoom = scale;//放大缩小相应倍数
    //   }
    // }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import './assets/css/base.css';
import './assets/css/hj.css';
import './assets/css/common.scss';
import 'element-ui/lib/theme-chalk/index.css';
import VueAMap from "vue-amap"; // 引入插件
Vue.config.productionTip = false


Vue.use(ElementUI);
// 浏览器窗口,这个地方值不会变,你任意拉扯浏览器也不会改变,获取的是你打开项目时的初始状态
Vue.prototype.width = window.innerWidth;
Vue.prototype.height = window.innerHeight;

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
Vue.use(VueAMap); // 使用插件
VueAMap.initAMapApiLoader({ // 初始化插件
  key: "dcbdb7e59a945ecc084c5c4e70b40f37", // 高德key，自己官网申请即可，这里是我申请的
  plugin: ["AMap.Geocoder"],  // 插件集合，这里只有一个定位功能，所以就只放一个AMap.Geocoder
  uiVersion: "1.0.11", // 不加会报错，加上吧
  v: "1.4.15", // 不加也不会影响，顺手加上吧
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

export const pc_routes = [
  {
    path: '*',
    component: () => import('@/views/pc/index.vue'),
  },
  {
    path: '/pc/index',
    name: 'index',
    component: () => import('@/views/pc/index.vue'),
  }, {
    path: '/pc/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/pc/aboutUs.vue'),
  }, {
    path: '/pc/productIntroduction',
    name: 'productIntroduction',
    component: () => import('@/views/pc/productIntroduction.vue'),
  }, {
    path: '/pc/usinessPartner',
    name: 'usinessPartner',
    component: () => import('@/views/pc/usinessPartner.vue'),
  }, {
    path: '/pc/joinUs',
    name: 'joinUs',
    component: () => import('@/views/pc/joinUs.vue'),
  },
  {
    path: '/pc/companyDevelopt',
    name: 'companyDevelopt',
    component: () => import('@/views/pc/companyDevelopt.vue'),
  },
]

export const mobile_routes = [
  {
    path: '/mobile/index',
    name: 'mobile_home',
    component: () => import('@/views/mobile/index.vue'),
  }, {
    path: '/mobile/aboutUs',
    name: 'mobile_aboutUs',
    component: () => import('@/views/mobile/aboutUs.vue'),
  }, {
    path: '/mobile/productIntroduction',
    name: 'mobile_productIntroduction',
    component: () => import('@/views/mobile/productIntroduction.vue'),
  }, {
    path: '/mobile/usinessPartner',
    name: 'mobile_usinessPartner',
    component: () => import('@/views/mobile/usinessPartner.vue'),
  }, {
    path: '/mobile/joinUs',
    name: 'mobile_joinUs',
    component: () => import('@/views/mobile/joinUs.vue'),
  },
  {
    path: '/mobile/companyDevelopt',
    name: 'mobile_companyDevelopt',
    component: () => import('@/views/mobile/companyDevelopt.vue'),
  },
]
